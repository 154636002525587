export type TermsPrivacyConsentProps = {
  linkStyle?: 'underline' | 'primary'
}

export const TermsPrivacyConsent = ({
  linkStyle = 'underline',
}: TermsPrivacyConsentProps) => (
  <p className='text-xs text-semantic-neutral-text-subtle'>
    By signing up, you agree to our{' '}
    <a
      target='_blank'
      rel='noreferrer'
      href='https://www.usemotion.com/terms'
      className={
        linkStyle === 'underline'
          ? 'underline'
          : 'text-semantic-primary-text-default'
      }
    >
      Terms of Service
    </a>{' '}
    and{' '}
    <a
      target='_blank'
      rel='noreferrer'
      href='https://www.usemotion.com/privacy'
      className={
        linkStyle === 'underline'
          ? 'underline'
          : 'text-semantic-primary-text-default'
      }
    >
      Privacy Policy
    </a>
  </p>
)
