import { XOutline } from '@motion/icons'
import { IconButton } from '@motion/ui/base'
import { BASIC_TIER_SEAT_LIMIT, Term } from '@motion/ui-logic/billing'
import {
  enterpriseTier,
  getExperimentalTierBullets,
  getExperimentalTierDescription,
  getTierAnnualPercentSavings,
  getTierBulletHeader,
  proTier,
  type Tier,
} from '@motion/ui-logic/tiered-pricing'
import {
  BaseTierCard,
  BillingPlanDropdown,
  BillingPlanToggle,
  BucketSeatsDropdown,
  useEnterpriseLink,
  useGetOrderedTiers,
  useGetRecommendedTier,
  useGetTierAnnualDollarSavings,
  useLowCostTrialExperiment,
} from '@motion/web-billing'
import { useExperiment } from '@motion/web-common/flags'

type ChooseFeatureTierPageProps = {
  email: string
  setFeatureTier: (tier: Tier) => void
  bucketSeats: number
  setBucketSeats: (seats: number) => void
  onBack: () => void
  term: Term
  setTerm: (term: Term) => void
}

export const ChooseFeatureTierPage = ({
  email,
  setFeatureTier,
  bucketSeats,
  setBucketSeats,
  onBack,
  term,
  setTerm,
}: ChooseFeatureTierPageProps) => {
  const isTeam = bucketSeats > 1
  const {
    annualTrialLength,
    monthlyTrialLength,
    lowCostTrialPriceId,
    lowCostAmount,
  } = useLowCostTrialExperiment(term, setTerm, isTeam, true)

  const filteredOrderedTiers = useGetOrderedTiers()
  const tieredPricingExpValue = useExperiment('tiered-pricing-exp').value

  const recommendedTier = useGetRecommendedTier(bucketSeats)

  const mostExpensiveNonEnterpriseTier = filteredOrderedTiers
    .toReversed()
    .find((tier) => tier !== 'ENTERPRISE')
  const tierAnnualDollarSavings = useGetTierAnnualDollarSavings(
    mostExpensiveNonEnterpriseTier,
    isTeam
  )
  const maxAnnualDollarSavings = mostExpensiveNonEnterpriseTier
    ? tierAnnualDollarSavings
    : 0

  const openEnterpriseLink = useEnterpriseLink()

  return (
    <div className='h-full overflow-y-scroll'>
      <div className='flex flex-col gap-4 bg-semantic-neutral-surface-raised-bg-default p-4 border-b border-semantic-neutral-border-subtle'>
        <div className='flex justify-between items-center'>
          <h1 className='text-lg font-semibold'>Choose a plan</h1>
          <IconButton
            onClick={onBack}
            icon={XOutline}
            variant='outlined'
            sentiment='neutral'
            size='small'
          />
        </div>
        <div className='flex flex-row justify-between'>
          <BucketSeatsDropdown
            minSeats={1}
            selectedBucket={bucketSeats}
            onChange={setBucketSeats}
            allowUpsell
          />

          {lowCostTrialPriceId ? (
            <BillingPlanDropdown
              annualTrialLength={annualTrialLength}
              monthlyTrialLength={monthlyTrialLength}
              term={term}
              setTerm={setTerm}
              annualDollarSavings={maxAnnualDollarSavings}
              popoverButtonStyle='custom'
              isTeam={isTeam}
            />
          ) : (
            <BillingPlanToggle
              term={term}
              setTerm={setTerm}
              savingsPercent={getTierAnnualPercentSavings(proTier, isTeam)}
            />
          )}
        </div>
      </div>
      <div className='flex flex-col gap-4 p-4'>
        {filteredOrderedTiers.map((tier: Tier) => (
          <div key={tier} className={tier === recommendedTier ? 'mt-6' : ''}>
            <BaseTierCard
              annualTrialLength={annualTrialLength}
              monthlyTrialLength={monthlyTrialLength}
              mobileEmail={email}
              tier={tier}
              term={term}
              mode='onboarding'
              description={getExperimentalTierDescription(
                tier,
                tieredPricingExpValue
              )}
              disabled={tier === 'BASIC' && bucketSeats > BASIC_TIER_SEAT_LIMIT}
              bulletHeader={getTierBulletHeader(tier, filteredOrderedTiers)}
              bulletItems={getExperimentalTierBullets(
                tier,
                tieredPricingExpValue
              )}
              numSeats={bucketSeats}
              recommended={tier === recommendedTier}
              collapsible={['BASIC', 'PRO'].includes(tier)}
              termText={term === Term.Annual ? 'annually' : 'monthly'}
              onClick={() => {
                if (tier === enterpriseTier) {
                  return openEnterpriseLink('mobileCheckout', email)
                }
                setFeatureTier(tier)
              }}
              lowCostAmount={lowCostAmount}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
