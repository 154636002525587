import { createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'
import {
  computeSavingsPercent,
  INDIVIDUAL_MONTHLY_PRICE,
  makeBillingPrices,
} from '@motion/ui-logic/billing'

export const useGetTeamPricesUnauthorized = createUseQuery(
  API.subscriptions.getTeamPricesUnauthorized,
  {
    select: (data) => {
      const teamPrices = makeBillingPrices(data.monthlyPrice, data.annualPrice)
      return {
        ...teamPrices,
        individualToTeamSavingsPercent: computeSavingsPercent(
          INDIVIDUAL_MONTHLY_PRICE,
          teamPrices.monthlyPrice,
          0
        ),
      }
    },
  }
)
