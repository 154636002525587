import './styles.css'

import { useOnValueChange } from '@motion/react-core/hooks'
import { Term } from '@motion/ui-logic/billing'
import { isEmailValid } from '@motion/utils/string'
import {
  getSegmentAnalytics,
  getTrackingCookies,
} from '@motion/web-base/analytics'
import { isMobileExperience } from '@motion/web-base/env'
import { Sentry } from '@motion/web-base/sentry'
import {
  markExposure,
  useExperiment,
  useHasTreatment,
} from '@motion/web-common/flags'
import {
  getLatestNavigationTiming,
  stats,
} from '@motion/web-common/performance'
import { type FeatureTier } from '@motion/zod/client'

import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, type User } from 'firebase/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { CheckoutPaymentPage } from './_components/checkout-payment-page'
import { ChooseFeatureTierPage } from './_components/choose-feature-tier-page'
import { ChoosePlanPage, type PlanType } from './_components/choose-plan-page'

import { setAnalyticsUserId } from '../analytics'
import { SignUpPage } from '../areas/auth/pages/sign-up-page'

const isMobile = isMobileExperience()

type CheckoutPageProps = {
  setUserEmail: (email: string) => void
}

export const CheckoutPage = ({ setUserEmail }: CheckoutPageProps) => {
  const [user, setUser] = useState<User | null | undefined>(undefined)
  // track this in state we don't want to send mobile users who already
  // auth'd into the new flow. they have to explicitly enter email first
  const [userEmailOverride, setUserEmailOverride] = useState('')
  const [trialOverrideMode, setTrialOverrideMode] = useState(false)

  const b2bOnMobileFlag = useExperiment('b2b-on-mobile')
  const noCcB2bEverywhere = useHasTreatment('no-cc-b2b-everywhere')
  const shouldShowB2bOnMobile = b2bOnMobileFlag.value === 'on'

  const isTieredPricingEnabled = useHasTreatment('tiered-pricing-v1', {
    track: true,
  })

  const teamSurveyModalEnabled =
    useExperiment('maximize-sal', { track: true }).value === 'on'

  const [urlEmail, setUrlEmail] = useState('')

  const [planType, setPlanType] = useState<PlanType | undefined>(undefined)
  const [featureTier, setFeatureTier] = useState<FeatureTier | undefined>()

  const auth = getAuth(getApp())

  const navigate = useNavigate()

  const cookieData = useMemo(() => getTrackingCookies(), [])
  const surveyCookieData = cookieData.survey_selection ?? undefined

  const [bucketSeats, setBucketSeats] = useState(5)
  const [term, setTerm] = useState<Term>(Term.Annual)
  useEffect(() => {
    switch (surveyCookieData) {
      case 'team':
        setPlanType('Team')
        break
      case 'individual':
        setPlanType('Individual')
        break
      default:
        return
    }
  }, [setPlanType, surveyCookieData])

  useOnValueChange(
    planType,
    (planType) => {
      if (planType === 'Team' || planType === 'Enterprise') {
        return setBucketSeats(5)
      }
      setBucketSeats(1)
    },
    { triggerOnFirstRender: true }
  )

  useEffect(function handleAnalytics() {
    getSegmentAnalytics()?.track('add_to_cart', {
      category: 'checkout',
      url: window.location.href,
    })
  }, [])

  const handleMobileSignIn = useCallback(
    (email: string, mode: 'email-code' | 'firebase' | 'manual') => {
      if (mode === 'manual') {
        stats.mark('checkout.login')
      }

      getSegmentAnalytics()?.track('checkout_sign_in_email', {
        url: window.location.href,
      })
      setUserEmail(email)
      setUserEmailOverride(email)
      getSegmentAnalytics()?.identify(email, {
        email: email,
      })
      setAnalyticsUserId(email)
      Sentry.setUser({ id: email })
    },
    [setUserEmail]
  )

  useEffect(
    function handleExposure() {
      if (isMobile) {
        markExposure('b2b-on-mobile')
        markExposure('tiered-pricing-exp')
        markExposure('tiered-pricing-pro-ai')
      }
    },
    [b2bOnMobileFlag]
  )

  useEffect(
    function handleAuth() {
      const newTrialOverride = new URLSearchParams(window.location.search).get(
        'newTrial'
      )
      if (newTrialOverride) {
        setTrialOverrideMode(true)
      }
      onAuthStateChanged(auth, (user) => {
        if (user) {
          if (!newTrialOverride && !isMobile) {
            const wasLoginPageVisible = stats.hasMark('checkout.login.visible')
            if (wasLoginPageVisible) {
              stats.mark('checkout.login')
            }

            navigate('/web')
            return
          }
          setUser(user)
        } else {
          setUser(null)
          const emailCode = new URLSearchParams(window.location.search).get('e')
          if (isMobile && emailCode) {
            try {
              const email = window.atob(emailCode)
              if (isEmailValid(email)) {
                if (!teamSurveyModalEnabled) {
                  return handleMobileSignIn(email, 'email-code')
                }
                setUrlEmail(email)
              }
            } catch (e) {
              Sentry.captureException(e, {
                tags: {
                  position: 'parseMobileEmailCode',
                },
              })
            }
          }

          const entry = getLatestNavigationTiming()
          if (entry == null) return

          stats.mark('checkout.login.visible')
          stats.distribution(
            'checkout.login.visible',
            performance.now() - entry.requestStart
          )
        }
      })
    },
    [auth, handleMobileSignIn, navigate, teamSurveyModalEnabled]
  )

  if (user === undefined) {
    return null
  }

  const userEmail = user?.email || userEmailOverride

  if (!userEmail) {
    return (
      <div className='fill' data-theme='light'>
        <SignUpPage
          title='👋 Try Motion for free!'
          handleMobileSignIn={(email) => handleMobileSignIn(email, 'manual')}
          disableEmailSignin={trialOverrideMode}
          setPlanType={setPlanType}
          prepopulatedEmail={urlEmail}
        />
      </div>
    )
  }

  if (
    ((shouldShowB2bOnMobile || isTieredPricingEnabled) && !planType) ||
    (planType === 'Enterprise' && noCcB2bEverywhere)
  ) {
    return <ChoosePlanPage setPlanType={setPlanType} email={userEmail} />
  }

  if (isTieredPricingEnabled && !featureTier) {
    return (
      <ChooseFeatureTierPage
        email={userEmail}
        setFeatureTier={setFeatureTier}
        bucketSeats={bucketSeats}
        setBucketSeats={setBucketSeats}
        onBack={() => setPlanType(undefined)}
        term={term}
        setTerm={setTerm}
      />
    )
  }

  return (
    <CheckoutPaymentPage
      userEmail={userEmail}
      signOutEmail={() => {
        auth.signOut()
        setUser(null)
        setUserEmailOverride('')
      }}
      isResubscribe={false}
      planType={
        featureTier ? (bucketSeats > 1 ? 'Team' : 'Individual') : planType
      }
      tier={featureTier}
      onBack={
        shouldShowB2bOnMobile
          ? () => setPlanType(undefined)
          : featureTier
            ? () => setFeatureTier(undefined)
            : undefined
      }
      bucketSeats={bucketSeats}
      setBucketSeats={setBucketSeats}
      term={term}
      setTerm={setTerm}
    />
  )
}
