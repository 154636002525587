import { Button, Modal } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

export const TriggerEmailNotFoundModal = () => {
  const api = useModalApi()
  const status = api.status('create-new-user')
  if (!status.visible) return null

  return (
    <LoginEmailNotFoundModal
      email={status.props.email}
      onSignUp={() => status.close(true)}
      onDismiss={() => status.close(false)}
    />
  )
}

// If the user tries to login with an email that is not found in the database, this modal will be displayed.
export const LoginEmailNotFoundModal = ({
  email,
  onSignUp,
  onDismiss,
}: {
  email: string
  onSignUp: () => void
  onDismiss: () => void
}) => {
  return (
    <Modal visible onClose={onDismiss} data-theme='light'>
      <div className='p-6 flex flex-col gap-6 max-w-[600px] items-center'>
        <div className='flex flex-col gap-2 font-bold items-center text-center text-semantic-neutral-text-default'>
          <p className='text-lg font-bold'>No existing account for {email}</p>
          <p className='text-base font-normal'>
            Did you mean to create a new account?
          </p>
        </div>
        <div className='flex flex-col gap-3'>
          <Button onClick={onSignUp} sentiment='primary'>
            Create new account
          </Button>
          <Button onClick={onDismiss} sentiment='neutral' variant='outlined'>
            Log in with another email
          </Button>
        </div>
        <p className='text-xs font-normal items-center text-center text-semantic-neutral-text-subtle'>
          If you are certain you have an existing Motion account, please check
          your email inboxes to see which email you’ve been receiving emails or
          invoices from Motion (harry@usemotion.app). Please make sure to log in
          with the same email account you signed up with. If you cannot login to
          your existing account, contact support at{' '}
          <a
            href='https://help.usemotion.com'
            target='_blank'
            className='underline hover:text-semantic-neutral-text-default'
            rel='noreferrer'
          >
            help.usemotion.com.
          </a>
        </p>
      </div>
    </Modal>
  )
}
