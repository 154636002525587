import { useExperiment } from '@motion/web-common/flags'

import { trialLength } from '../checkout/constants'

export function useIndividualTrialLength(track: boolean): number {
  const flag = useExperiment('stripe-trial-length', { track })
  return flag.payload?.days ?? trialLength
}

export const useTrialLength = (isTeam: boolean): number => {
  const individualTrialLength = useIndividualTrialLength(!isTeam)
  return isTeam ? trialLength : individualTrialLength
}

export function useOnboardingIframeUrl(page: string): string {
  const flag = useExperiment('onboarding-iframes')
  return flag.payload?.[page]?.url || ''
}
