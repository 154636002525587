import { templateStr } from '@motion/react-core/strings'
import { Banner, Button } from '@motion/ui/base'
import { RadioButton, TextField } from '@motion/ui/forms'
import { LoginScreen } from '@motion/ui/login'
import { isEnterpriseEmail } from '@motion/ui-logic'
import { type PlanType } from '@motion/ui-logic/billing'
import { isEmailValid } from '@motion/utils/string'
import { getSegmentAnalytics } from '@motion/web-base/analytics'
import { useEnterpriseLink } from '@motion/web-billing'
import { useExperiment } from '@motion/web-common/flags'

import { useState } from 'react'

import { TermsPrivacyConsent } from '../components/terms-privacy-consent'
import { MobileWebModal } from '../modals'

const teamSurveyOptions = ['Just me', 'Between 2 and 14', '15 or more'] as const
type TeamSurveyOptionType = (typeof teamSurveyOptions)[number]

interface MobileSignUpPageProps {
  title: string
  handleSignInEmail: (email: string) => void
  setPlanType: (plan: PlanType) => void
  prepopulatedEmail?: string
}

export const MobileSignUpPage = ({
  title,
  handleSignInEmail,
  setPlanType,
  prepopulatedEmail,
}: MobileSignUpPageProps) => {
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false)
  const [email, setEmail] = useState<string>(prepopulatedEmail ?? '')

  const [activeModal, setActiveModal] = useState<
    'confirmation' | 'teamSurvey' | 'enterpriseEmail' | null
  >(prepopulatedEmail ? 'teamSurvey' : null)

  const teamSurveyModalEnabled = useExperiment('maximize-sal').value === 'on'

  const openEnterpriseLink = useEnterpriseLink()

  const handleEnterpriseEmail = (workEmail: string, onError: () => void) => {
    if (!isEnterpriseEmail(workEmail)) {
      onError()
    } else {
      setPlanType('Enterprise')
      handleSignInEmail(workEmail)
      openEnterpriseLink('mobileCheckout', email, workEmail)
    }
  }

  const onSignupClick = () => {
    if (isEmailValid(email)) {
      setEmailInvalid(false)
      setActiveModal('confirmation')
    } else {
      setEmailInvalid(true)
    }
  }

  const renderLoginButtons = () => {
    return (
      <>
        <div className='flex flex-col gap-3 w-full text-center'>
          <TextField
            placeholder='Enter email'
            type='email'
            value={email}
            onChange={(value) => setEmail(value.toLowerCase())}
          />
          {emailInvalid && (
            <Banner variant='onPage' sentiment='error'>
              Invalid email. Please try again
            </Banner>
          )}
          <TermsPrivacyConsent />
          <Button
            // @ts-expect-error - old styles
            className='checkout-survey-submit-button'
            fullWidth
            // @ts-expect-error - old styles
            style={{ height: 48 }}
            onClick={onSignupClick}
          >
            Sign up
          </Button>
          <p className='text-xs text-semantic-neutral-text-subtle'>
            Already have an account?{' '}
            <a className='text-semantic-primary-text-default' href='/'>
              Log in
            </a>
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <LoginScreen
        iframeUrl=''
        title={title}
        subtitle='Motion is guaranteed to help you get more deep work done. Cancel
      anytime in 1 click.'
      >
        {renderLoginButtons()}
      </LoginScreen>
      {activeModal === 'confirmation' && (
        <CheckoutSurveyModal
          setShowTeamSurveyModal={() => setActiveModal('teamSurvey')}
          handleSignInEmail={handleSignInEmail}
          onClose={() => setActiveModal(null)}
          teamSurveyModalEnabled={teamSurveyModalEnabled}
          email={email}
        />
      )}
      {activeModal === 'teamSurvey' && (
        <TeamSurveyModal
          onClose={() => setActiveModal(null)}
          onAction={(selection) => {
            getSegmentAnalytics()?.track('MOBILE_WEB_TEAM_SURVEY_SELECTION', {
              selection,
            })

            switch (selection) {
              case 'Just me':
                setPlanType('Individual')
                break
              case 'Between 2 and 14':
                setPlanType('Team')
                break
              case '15 or more':
                return handleEnterpriseEmail(email, () =>
                  setActiveModal('enterpriseEmail')
                )
            }
            handleSignInEmail(email)
          }}
        />
      )}
      {activeModal === 'enterpriseEmail' && (
        <EnterpriseEmailModal
          onClose={() => setActiveModal(null)}
          onAction={handleEnterpriseEmail}
        />
      )}
    </>
  )
}

type CheckoutSurveyModalProps = {
  setShowTeamSurveyModal: (show: boolean) => void
  handleSignInEmail: (email: string) => void
  onClose: () => void
  teamSurveyModalEnabled: boolean
  email: string
}

const CheckoutSurveyModal = ({
  setShowTeamSurveyModal,
  handleSignInEmail,
  onClose,
  teamSurveyModalEnabled,
  email,
}: CheckoutSurveyModalProps) => {
  return (
    <MobileWebModal onClose={onClose} className='p-6'>
      <div className='flex flex-col gap-4'>
        <h3 className='font-bold text-2xl'>Welcome to Motion!</h3>
        <p>
          {templateStr(
            'Please confirm the email {{email}} is correct (no typos).',
            {
              email: <span className='font-bold'>{email}</span>,
            }
          )}
        </p>
        <TermsPrivacyConsent linkStyle='primary' />
        <div className='flex-col flex w-full gap-2'>
          <Button
            onClick={() => {
              if (teamSurveyModalEnabled) {
                return setShowTeamSurveyModal(true)
              }
              handleSignInEmail(email)
            }}
            fullWidth
          >
            Confirm
          </Button>
          <Button
            onClick={onClose}
            sentiment='neutral'
            variant='outlined'
            fullWidth
          >
            Change email
          </Button>
        </div>
        <p className='text-xs text-center text-semantic-neutral-text-subtle'>
          Already have an account?{' '}
          <a className='text-semantic-primary-text-default' href='/'>
            Log in
          </a>
        </p>
      </div>
    </MobileWebModal>
  )
}

type TeamSurveyModalProps = {
  onClose: () => void
  onAction: (selection: TeamSurveyOptionType) => void
}

const TeamSurveyModal = ({ onClose, onAction }: TeamSurveyModalProps) => {
  const [teamSurveySelection, setTeamSurveySelection] =
    useState<TeamSurveyOptionType | null>(null)

  return (
    <MobileWebModal onClose={onClose}>
      <div className='flex flex-col gap-2'>
        <h1 className='text-semantic-neutral-text-default font-semibold text-base'>
          How many team members do you plan to have using Motion over the next 6
          months?
        </h1>
        <div className='flex flex-col gap-3'>
          {teamSurveyOptions.map((option, idx) => {
            return (
              <RadioButton
                label={option}
                key={idx}
                checked={teamSurveySelection === option}
                onChange={(value) => {
                  if (value) {
                    setTeamSurveySelection(option)
                  }
                }}
              />
            )
          })}
        </div>
        <Button
          disabled={!teamSurveySelection}
          onClick={() => {
            if (!teamSurveySelection) {
              return
            }
            onAction(teamSurveySelection)
          }}
          fullWidth
        >
          Submit
        </Button>
        <Button sentiment='neutral' onClick={onClose} fullWidth>
          Go Back
        </Button>
      </div>
    </MobileWebModal>
  )
}

type EnterpriseModalProps = {
  onClose: () => void
  onAction: (newEmail: string, onError: () => void) => void
}

const EnterpriseEmailModal = ({ onClose, onAction }: EnterpriseModalProps) => {
  const [workEmail, setWorkEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  return (
    <MobileWebModal onClose={onClose}>
      <div className='flex flex-col gap-2'>
        <h1 className='text-semantic-neutral-text-default font-semibold text-base'>
          What is your work email?
        </h1>
        <TextField
          placeholder='Work Email'
          onChange={(email) => setWorkEmail(email)}
        />
        {errorMessage && (
          <div className='text-semantic-error-text-default text-xs'>
            {errorMessage}
          </div>
        )}
        <Button
          onClick={() => {
            onAction(workEmail, () =>
              setErrorMessage(
                `${workEmail} is not a valid work email, please enter an email for your company's domain`
              )
            )
          }}
          fullWidth
        >
          Submit
        </Button>
        <Button sentiment='neutral' onClick={onClose} fullWidth>
          Go Back
        </Button>
      </div>
    </MobileWebModal>
  )
}
