import { QuestionMarkCircleSolid } from '@motion/icons'
import { useOnceWhen } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import {
  INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY,
  individualElevatorPitch,
  MAX_PRO_TEAM_INITIAL_SIZE,
  PlanNames,
  teamElevatorPitch,
} from '@motion/ui-logic/billing'
import { logEvent } from '@motion/web-base/analytics'
import { useEnterpriseLink } from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'
import {
  getLatestNavigationTiming,
  stats,
} from '@motion/web-common/performance'

import { LoadingPage } from '~/areas/global'

import { MobileBillingPlanCard } from './mobile-billing-plan-card'

import { useGetTeamPricesUnauthorized } from '../hooks'

export type PlanType = 'Individual' | 'Team' | 'Enterprise'

type ChoosePlanPageProps = {
  setPlanType: (planType: PlanType | undefined) => void
  email: string
}

export const ChoosePlanPage = ({ setPlanType, email }: ChoosePlanPageProps) => {
  const { data: teamPrices } = useGetTeamPricesUnauthorized({ email })
  const openEnterpriseLink = useEnterpriseLink()
  const shouldUseTieredPricing = useHasTreatment('tiered-pricing-v1')

  useOnceWhen(teamPrices != null, () => {
    if (!teamPrices) return
    const mark = stats.getLatestMark('checkout.login')
    if (mark != null) return

    const nav = getLatestNavigationTiming()
    if (nav == null) return
    stats.distribution(
      'checkout.choose-plan',
      performance.now() - nav.requestStart
    )
  })

  if (!teamPrices) return <LoadingPage id='choose-plan-page' />

  function onPlanSelection(plan: PlanType) {
    logEvent('MOBILE_WEB_CHECKOUT_PLAN_CLICK', {
      plan,
    })
    stats.mark('checkout.choose-plan.selected')
    if (plan === 'Enterprise') {
      setPlanType('Enterprise')
      return openEnterpriseLink('mobileCheckout', email)
    }

    setPlanType(plan)
  }

  return (
    <div className='fill bg-semantic-neutral-bg-subtle flex flex-col items-center px-4 pt-6 gap-8'>
      <a
        href='https://help.usemotion.com'
        target='_blank'
        rel='noreferrer'
        className='w-full flex items-center justify-end gap-2 text-semantic-neutral-text-subtle'
      >
        <QuestionMarkCircleSolid className='size-4' />
        Support
      </a>
      <div className='flex flex-col gap-4 items-center'>
        <h1 className='text-lg font-bold text-semantic-neutral-text-default'>
          Welcome to Motion!
        </h1>
        <h2 className='text-base text-semantic-neutral-text-subtle text-center'>
          Do you plan on using Motion by yourself, or with your team?
        </h2>
      </div>
      <div className='flex flex-col gap-4'>
        <MobileBillingPlanCard
          subtitle={individualElevatorPitch}
          onClick={() => onPlanSelection('Individual')}
          priceElement={makePriceStrElement(
            INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY
          )}
          title={PlanNames.Individual}
          buttonText={
            shouldUseTieredPricing ? 'Continue' : 'Try Motion for free'
          }
        />
        <MobileBillingPlanCard
          subtitle={teamElevatorPitch}
          onClick={() => onPlanSelection('Team')}
          priceElement={makePriceStrElement(
            teamPrices.annualPricePerMonth,
            true
          )}
          savingsDescription={templateStr(
            '{{savings}}% cheaper than Individual',
            { savings: teamPrices.individualToTeamSavingsPercent }
          )}
          title={PlanNames.Team}
          buttonText={
            shouldUseTieredPricing ? 'Continue' : 'Try Motion for free'
          }
        />
        <MobileBillingPlanCard
          subtitle='Dedicated and ongoing project management expert to help you set everything up and onboard your entire team ($10,000 worth of value) for free.'
          onClick={() => onPlanSelection('Enterprise')}
          savingsDescription='Free expert help'
          title={PlanNames.Enterprise}
          priceElement={
            <p className='text-xs font-bold text-semantic-neutral-text-default'>
              {templateStr('{{maxSize}} seats or more', {
                maxSize: MAX_PRO_TEAM_INITIAL_SIZE,
              })}
            </p>
          }
          buttonText='Book demo'
        />
      </div>
    </div>
  )
}

const makePriceStrElement = (price: number, isTeam = false) => {
  return (
    <p className='text-xs text-semantic-neutral-text-subtle'>
      {templateStr('{{price}}/month{{teamStr}}', {
        price: (
          <span className='text-semantic-neutral-text-default font-bold'>
            ${price}
          </span>
        ),
        teamStr: isTeam ? ' per user' : '',
      })}
    </p>
  )
}
