import { Link } from 'react-router-dom'

export const AlreadyHaveAccount = () => {
  return (
    <p className='text-xs text-semantic-neutral-text-subtle'>
      Already have an account?{' '}
      <Link className='text-semantic-primary-text-default' to='/'>
        Log in
      </Link>
    </p>
  )
}
